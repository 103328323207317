<template>
  <div id="app">
    <Nav />
    <div id="content">
        <div class="teasers" v-show="!entryMode" @click="entryMode = !entryMode" key=1>
          <Teaser :img="entry.teaserImg" :title="entry.title" v-for="(entry, index) in entries" :key="index" @click.native="activateEntry(index)" />
          <!--<Teaser img="alima.jpg" />-->
        </div>
        <div class="entries" v-if="entryMode" key=2>
          <Entry :entry="entries[activeEntry]" />
        </div>
    </div>
  </div>
</template>

<script>
import Nav from './components/Nav.vue'
import Teaser from './components/Teaser.vue'
import Entry from './components/Entry.vue'

export default {
  name: 'App',
  components: {
    Nav, Teaser, Entry
  },
  methods: {
    activateEntry(index) {
      console.log(index)
      console.log(this.entries[index])
      this.activeEntry = index
    }
  },
  data() {
    return {
      entryMode: false,
      activeEntry: 0,
      entries: [
        {
          title: 'Fremd zuhause',
          teaserImg: 'overpass.jpg',
          slides: [
            {
              titleslide: true,
              title: 'Das Projekt',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Alle fühlen sich manchmal fremd. Und doch blenden wir das eigene Fremdsein oft aus, projizieren es auf andere. Was passiert, wenn wir die vertraute Umgebung einmal mit anderen Augen sehen, mit anderen Ohren hören?",
                }
              ]
            },
            {
              titleslide: false,
              title: 'Ein interaktiver Audiowalk',
              underTitle: "<p>Der interaktive Audiowalk <em>Fremd Zuhause: Versuche zur Desintegration</em> lotet das eigene Fremdsein aus. Er besteht aus einem Spaziergang durch einen vertrauten Ort, entlang dessen das Publikum fünf hörspielartige Soundcollagen aus literarischen Texten, Geräuschen und Musik hört. Ein theatraler Spaziergang für eine Person, der dazu einlädt, die eigene Wahrnehmung zu hinterfragen und so die Grenzen von fremd und vertraut zu verschieben.<br><br> Text & Dramaturgie: Christina Caprez<br> Ton & Musik: Martin Bezzola<br> Raum & Handlungsanweisungen: Karin Bucher<br>Web & Grafik: Jane Schindler.</p>",
              main: [
                {
                  type: 'image',
                  src: 'screens.jpg'
                }
              ]
            },
            {
              titleslide: true,
              smalltext: true,
              background: '#000',
              title: 'Text & Dramaturgie',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Wir suchen Texte von Autor*innen, primär aus der Ostschweiz, die Fremdheitserfahrungen thematisieren. Diese Texte setzen wir in Soundcollagen aus literarischen Texten, Geräuschen und Musik zueinander in Bezug. So laden wir das Publikum dazu ein, sich mit eigenen Fremdheitserfahrungen auseinanderzusetzen.",
                }
              ]
            },
            {
              titleslide: true,
              smalltext: true,
              background: '#000',
              title: 'Raum & Handlungs&shy;anweisungen',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Die Erzählstimme führt die Spazierenden an Orte, die ihnen vertraut sind, und fordert sie auf, sie durch fremde Augen zu betrachten. An jeder Station hören die Spazierenden eine passende Soundcollage. So erzählt die Bushaltestelle vom Weggehen und Ankommen, die Kirche davon, wie unsere Glaubenssätze unsere Sicht auf die Welt prägen.",
                }
              ]
            },
            {
              titleslide: true,
              smalltext: true,
              background: '#000',
              title: 'Ton & Musik',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Das Sounddesign setzt die Fremdheitserfahrung akustisch um und lässt das Publikum in eine (immer wieder) fremde und irritierende Welt eintauchen. Wir spielen mit der Frage: Was ist fremd, was vertraut? Was ist echt, was höre ich nur in meinem Kopf? Und ist das dann weniger echt?",
                }
              ]
            },
            {
              titleslide: true,
              smalltext: true,
              background: '#000',
              title: 'Web & Grafik',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "In der Web-App zum Projekt können die Spazierenden ihren eigenen massgeschneiderten Rundgang kreieren: Unser «Spaziergangsgenerator» generiert den Walk am Ort der Wahl neu. Die Parameter - vom Spielplatz über das Gotteshaus zur Busstation - bleiben immer gleich. Entlang des Weges machen die Spazierenden ein Foto oder eine Notiz, laden sie hoch und verorten sie auf der Karte. So entsteht mit der Zeit eine alternative Geografie von Fremdheitserfahrungen.",
                }
              ]
            },
          ]
        },
        {
          title: 'Erste Lektüren',
          teaserImg: 'carter1.png',
          slides: [
            {
              titleslide: true,
              title: '2. <br>Erste Lektüren',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Über die Festtage habe ich ein paar Bücher von Ostschweizer Autor*innen gelesen, die in der einen oder anderen Form das Fremdsein thematisieren.",
                }
              ]
            },
            {
              titleslide: false,
              background: '#000',
              title: 'Angelika Overath: Alle Farben des Schnees',
              underTitle: "<p>Angelika Overath: <em>Alle Farben des Schnees</em> beschreibt das Ankommen einer Deutschen Autorin und ihrer Familie in Sent. Die Ferienwohnung im Unterengadin wird zum permanenten Wohnsitz, die Familie lernt Romanisch, die Autorin beobachtet ihre neue Umgebung mit einem ethnografisch-journalistischen Interesse, der Sohn wird im Nu Mitglied der Dorfbande. Ich habe mir ein paar zitierenswerte Passagen notiert wie: „Immer wieder die Bemerkung: Das war aber mutig von euch. (…) Ich denke: Ist es nicht auch mutig, einfach weiterzuleben, wie man lebt?“ Oder beim Dorffest: „Ich möchte nicht heimgehen, aber ich weiss nicht, wohin, zu wem ich mich setzen soll. Die Tische sind nicht zufällig belegt. Das sehe ich, ohne die Ordnung genau benennen zu können.“</p>",
              main: [
                {
                  type: 'image',
                  src: 'overath.jpeg'
                }
              ]
            },
            {
              titleslide: false,
              title: 'Arno Camenisch: Der Schatten über dem Dorf / Usama Al Shahmanis Im Fallen lernt die Feder fliegen',
              underTitle: "<p>Auf Rat des Pontresiner Buchhändlers habe ich Arno Camenisch: <em>Der Schatten über dem Dorf</em>, gelesen. Das Buch erzählt von einer Kindheit in der Surselva in den 1980er Jahren und von einem tragischen Unglück und dem Umgang der Dorfgemeinschaft damit. Fein geschrieben, ein aufschlussreicher Einblick in das Leben in einem kleinen Dorf - ich finde darin jedoch nichts zum Thema Fremdsein.</p><p>Usama Al Shahmanis <em>Im Fallen lernt die Feder fliegen</em> berichtet davon, wie eine irakische Geflüchtete in der Schweiz verstummt. Sie findet die Worte nicht, ihrem Schweizer Freund von ihrer Flucht zu erzählen, zu traumatisch sind die Erlebnisse der Vergangenheit. Ein interessanter Text über eine Entwurzelung, Fremdsein ist das Hauptmotiv des Buches - es ist jedoch schwierig, einzelne Sätze herauszugreifen.</p>",
              main: [
                {
                  type: 'image',
                  src: 'feder_schatten.jpg'
                }
              ]
            },
            {
              titleslide: false,
              title: 'Vincent O. Carters Meine weisse Stadt',
              underTitle: "<p>Vincent O. Carters <em>Meine weisse Stadt und ich</em> hat keinen Ostschweizbezug. Es ist ein tagebuchartiger Bericht eines afroamerikanischen Schriftstellers, der 1944 als umjubelter GI Europa befreit, Anfang der 1950er Jahre zurückkehrt und nur noch als Schwarzer Mann gelesen wird, dessen Anwesenheit in Paris und in Bern (wo er sich dann niederlässt) als erklärungsbedürftig angesehen wird. Eine anschauliche Reportage über das Bern der 1950er Jahre aus einem ganz neuen Blickwinkel. Auch hier eignen sich einzelne Sätze kaum zum Herausgreifen.</p>",
              main: [
                {
                  type: 'image',
                  src: 'carter.jpeg'
                }
              ]
            },
            {
              titleslide: false,
              background: '#000',
              title: 'Samira El-Maawis In der Heimat meines Vaters',
              underTitle: "<p>Samira El-Maawis <em>In der Heimat meines Vaters</em> riecht die Erde wie der Himmel erzählt aus Kinderperspektive von einem entwurzelten Vater, der einst aus Tansania in die Schweiz kam. Sinnlich erzählt (die Gerüche des kochenden Vaters spielen eine wichtige Rolle), veranschaulicht dieser Text, was es heisst, in der Schweiz doppelt fremd zu sein - aufgrund der kulturellen Distanz und des Rassismus. Ich habe das Buch der Zürcher Autorin bei seinem Erscheinen vor einem Jahr gelesen.</p>",
              main: [
                {
                  type: 'image',
                  src: 'elmaawi.jpeg'
                }
              ]
            },
          ]
        },
        {
          title: 'Was Google sagt',
          teaserImg: 'alima.jpg',
          slides: [
            {
              titleslide: true,
              title: 'Was Google sagt',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Zunächst wollen wir herausfinden, ob Google die Orte, die wir im Spaziergang integrieren möchten (Busstation, Kirche etc.) findet. Dazu konsultieren wir Googles Interface ('API') für Programmierer*innen.",
                }
              ]
            },
            {
              titleslide: false,
              title: '3.1 Map Tester',
              underTitle: "<p>Die unerfreuliche Überraschung: Googles Interface für Programmierer*innen ist nicht vergleichbar mit der Kartensoftware. Ich habe einen kleinen Kartentester gebaut, um herauszufinden, wie zuverlässig Googles Interface die von uns gewünschten Orte findet. Die Resultate für mein Quartier waren ziemlich schlecht, vor allem für den Parameter «Bushaltestelle». Offenbar geht Google davon aus, dass ‘Fashn Pie, D. Fanetti’, ‘Birom AG c/o Dr. Herbert Pfortmüller’ and ‘R. Gägauf Nähatelier für Inneneinrichtungen’ Bushaltestellen in meiner Nähe sind. Auch Pärke findet Google nicht zuverlässig.</p><p>Der aktuelle Kartentester sucht nach: ['primary_school', 'supermarket', 'park', 'cemetery', 'bus_station', 'church', 'atm']. Die Google-Liste für mögliche Orte findet sich <a href='https://developers.google.com/maps/documentation/places/web-service/supported_types' target='_blank'>hier</a>. Der Tester sortiert 20 Resultate nach Distanz zum gewählten Ausgangspunkt und wählt den nächstmöglichen. Möchtest du selber testen? Dann klicke irgendwo im Plan und schaue, was der Plan in deiner Umgebung findet.</p>",
              main: [
                {
                  type: 'mapTest',
                }
              ]
            },
            {
              titleslide: false,
              title: 'Austausch mit Google',
              underTitle: "<p>Ich beschloss, das Tech-Team von Google Maps um Hilfe zu bitten. Sie antworteten praktisch umgehend und sehr nett. Ich bin dennoch nicht sicher, ob sie ihr Interface grundlegend überarbeiten werden für unser Kunstprojekt.</p>",
              main: [
                {
                  type: 'image',
                  src: 'google.jpg'
                }
              ]
            },
            {
              titleslide: false,
              title: 'Nächste Schritte',
              underTitle: "<p>Die kommenden Wochen sollten wir den Kartentester ausprobieren und herausfinden, wie wir ihn verändern können. Es kommen verschiedene Optionen in Betracht: Wir können das Interface einer anderen Programmiersoftware verwenden; wir könnten andere Orte auf der Google-Liste ausprobieren. </p>",
              main: [
                {
                  type: 'image',
                  src: 'dev_screen.jpg'
                }
              ]
            },
          ]
        },
        {
          title: 'Erste Field-Recordings',
          teaserImg: 'sihlfeld.jpeg',
          slides: [
            {
              titleslide: true,
              title: 'Erste Field-Recordings',
              underTitle: 'Erste Field-Recordings, um die Möglichkeiten der „augmented reality“ auf der Audio-Ebene zu prüfen. Welche Geräusche nehmen wir als Teil der Realität ausserhalb der Tonspur unseres Audiowalks wahr, also der Sound der von aussen durch die Kopfhörer dringt? Welche Geräusche können wir künstlich hinzufügen? Ab wann werden sie als Fremdkörper wahrgenommen und weshalb?',
              main: [
                {
                  type: 'video',
                  src: 'https://player.vimeo.com/video/689702915?h=7e70bd9123',
                }
              ]
            },
            
          ]
        },
        {
          title: 'Orte',
          teaserImg: 'bushaltestelle.jpg',
          slides: [
            {
              titleslide: true,
              title: 'Orte',
              underTitle: 'Was haben bestimmte Ort gemeinsam? <br>Welche Themen sind an welchen Orten eingeschrieben und verhandelbar?<br>Jede Busstation hat eine Bank, einen Papierkorb, ein Schild/Sprache.<br>Der Ort stellt eine Verbindung zur Welt her. Themen wie Reisen, Abschied, Ankommen, Heimweh sind anwesend. Wir erstellen eine Liste mit Orten / Gleichheiten / Themen',
              main: [
                {
                  type: 'image',
                  src: 'haltestellen.jpeg',
                }
              ]
            },
          ]
        },
        {
          title: 'Überlegungen zu den Hörstücken',
          teaserImg: 'alshahmani.jpg',
          slides: [
            {
              titleslide: true,
              title: 'Überlegungen zu den Hörstücken',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Ich komme immer mehr zum Schluss, dass sich bestehende Literatur nicht eignet für unser Vorhaben. In 6- bis 7-Minuten-Stücken haben nur zwei, drei kurze Literaturfragmente Platz, das stellt spezifische Anforderungen an den Text (er muss für sich funktionieren, z.B. als Aphorismus) und wird der Literatur nicht gerecht. Das bringt mich auf weiterführende Ideen:",
                }
              ]
            },
            {
              titleslide: true,
              smalltext: true,
              background: '#000',
              title: 'Idee 1',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Wir bitten Autor*innen aus der Ostschweiz, für die spezifischen Orte unseres Audiowalks kurze Auftragstexte zu verfassen, die als Fragmente funktionieren. Sie können sich dann in die Stimmung hineinversetzen, die der betreffende Ort bei ihnen hervorruft.",
                }
              ]
            },
            {
              titleslide: true,
              smalltext: true,
              background: '#000',
              title: 'Idee 2',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Wir lesen literarische Passagen von mehreren Minuten, die als Überleitungstexte zwischen den einzelnen Stationen dienen könnten. Also: Angelika Overaths Reflexion über das Ankommen im Dorf begleitet uns vom Spielplatz bis zur Kirche. Und mit dem inneren Monolog über das Gefühl der Zerrissenheit der Protagonistin von Usama Al Shahmani im Ohr gehen wir weiter zur Bushaltestelle.",
                }
              ]
            },
            {
              titleslide: true,
              smalltext: true,
              background: '#000',
              title: 'Idee 3',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Ende Februar war ich am Hörkunstfestival Sonohr in Bern und habe mehrere Audiowalks mitgemacht, was sehr inspirierend war. Darunter Beyond Vision von Ilona Marti, der einen mit verbundenen Augen in ganz unterschiedliche Welten entführte (an einen See, an ein Fest, auf eine Geisterbahn). Eindrücklich, welche Emotionen rein durch das Audio hervorgerufen werden. Ich überlege, das Thema „Fremdsein“ in unserem Projekt zu erweitern. Auf welche Weise fühlt sich eine blinde Person fremd in einer Welt, die für Sehende geschaffen wurde? Und eine queere Person in der heteronormativen Gesellschaft? Denkbar wäre, an jeder Station einen anderen Aspekt von Fremdheit zu thematisieren.",
                }
              ]
            },
          ]
        },
        {
          title: 'Handlungs&shy;anweisungen',
          teaserImg: 'china.jpeg',
          slides: [
            {
              titleslide: true,
              title: 'Handlungs&shy;anweisungen',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Handlungsanweisungen sind Aufforderungen, sie regeln das Zusammenleben und sind je nach Kulturen unterschiedlich. Unsere Handlungsanweisungen fordern den/ die HörerIn auf die Orte wie ein Fremder zu erkunden. Sie rufen vertraute oder fremde Erfahrungen und damit verknüpfte Erinnerungen und Emotionen hervor.",
                }
              ]
            },
            {
              titleslide: false,
              title: 'Handlungs&shy;anweisungen',
              underTitle: `<p>Ich erstelle eine erste Liste. Hier ein erstes Beispiel: </p>
              <p><em>Drehe dich um, gehe ein Stück rückwärts. langsam, wir haben Zeit. In den Pärken Chinas machen dies die Menschen, um andere Muskeln zu trainieren, es soll die Sinne und die Denkfähigkeit schärfen.</em></p>
              <p>Die koloniale Mentalität sieht das Gehen als eine Art die Welt zu entdecken, das Gehen als eine Art die Welt zu erobern. Aber die Welt ist schon da. Wir gehen nur, damit die Welt auf uns zukommt. Dies erfordert eine ganz andere Art des Gehens.</p>
`,
              main: [
                {
                  type: 'image',
                  src: 'china.jpeg',
                }
              ]
            },

          ]
        },
        {
          title: 'Open street maps',
          teaserImg: 'osm.jpg',
          slides: [
            {
              titleslide: true,
              title: 'Open street maps',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Ich habe das Programmier-Interface von Google aufgegeben und halte stattdessen nach einer Alternative Ausschau. Das beste, die ich bisher gefunden habe, ist das open street map POI (points of interest) Service, das ein Programmier-Interface namens Overpass hat.",
                }
              ]
            },
            {
              titleslide: false,
              title: 'Tester 2',
              underTitle: "<p>Dieser Prototyp basierend ist vielversprechender. Er findet 'school', 'supermarket', 'playground', 'bus', 'worship', 'cemetery', 'garden', 'fountain', 'sport', 'recycling' und 'bench', auf der Basis der Programmier-Interface des Open Street Map. Hier gibt es allerdings keine Funktion, die den nächstmöglichen Standort der gesuchten Kategorie findet. Ich arbeite darum mit fixen Distanzen: Zuerst sucht der Prototyp bist zu 500 Meter Distanz. Falls er nichts findet, erweitert er den Suchradius um jeweils 100 Meter bis zu 5000 Metern. Ich möchte diesen Prozess noch effizienter gestalten. Zudem muss ich einen Weg finden, wie das Programm eine einzelne Option auswählt, wenn es mehrere zur Auswahl gibt. <em>Möchtest du den Prototypen ausprobieren? Klicke irgendwo in die Karte, um Standorte zu finden.</em></p>",
              main: [
                {
                  type: 'mapTestOsm',
                }
              ]
            },
            {
              titleslide: false,
              title: 'Weitere Entwicklung',
              underTitle: "<p>Es wäre schön, wenn wir die Ungewissheit in den Spaziergang einbauen könnten. Wir können nicht davon ausgehen, dass unser Spaziergangsgenerator immer den nächstmöglichen Lebensmittelladen oder Kiosk findet – der Spaziergang wird machbar sein, aber mit einem gewissen Mass an Unvorhersehbarkeit. Das ist vielleicht gar nicht so schlecht für unser Projekt.</p>",
              main: [
                {
                  type: 'image',
                  src: 'unterfuehrung.jpg'
                }
              ]
            },
          ]
        },
        {
          title: 'Wegfindung',
          teaserImg: 'osm-vs-google.jpg',
          slides: [
            {
              titleslide: true,
              title: 'Wegfindung',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Jetzt wollen wir eine Route für die Stationen holen. Wir haben nun eine Art Minimalvariante, die wir verbessern können.",
                }
              ]
            },
            {
              titleslide: false,
              title: 'Tester 3',
              underTitle: "<p>Unser Tester muss jetzt 3 Tasks erledigen: 1) Stationen finden ('playground', 'bus', 'worship', 'cemetery', 'fountain', 'bench'); 2) Stationen in eine logische Reihenfolge bringen; 3) einen Spazierweg finden. Wir testen hier zwei Routen-Dienste: Openrouteservice (schwarz) und Google (blau). Beide sind auf Fussgänger-Modus gestellt, und beide starten und enden am Klickpunkt. Nächste Schritte wären: Stationen differenzierter auswählen, Spezialfälle behandeln.  <em>Möchtest du den Prototypen ausprobieren? Klicke irgendwo in die Karte, um Standorte zu finden.</em></p>",
              main: [
                {
                  type: 'mapTestOsmDirections',
                }
              ]
            },
            {
              titleslide: true,
              smalltext: true,
              background: '#000',
              title: 'Idee 3',
              underTitle: '',
              main: [
                {
                  type: 'bigText',
                  text: "Bei 'Fountain' habe ich Trinkbrunnen ausgeschlossen – es werden also nur etwas 'speziellere' Brunnen gefunden...Falls es mehrere Objekte einer Kategorie gibt, wird momentan einfach das erste genommen. Weitere Wünsche aus der Gruppen sind: Min.- und Max.-Länge, Bänke im Grünen bevorzogen, allgemein nichts, was auch privat ist, regelmässige/gut verteilte Distanzen.",
                }
              ]
            },
          ]
        },
      ]
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">
@media(min-width: 1200px) {
  :root {
    font-size: 1.1vw;
  }
}
img {
  max-width: 100%;
}
body { margin: 0; }
#app {
  font-family: 'Favorit', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
#content {
  margin-top: 4rem;
}
.teasers {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
button {
  padding: 0.75rem 1rem;
  border-radius: 500px;
  border: 1px solid #000;
  background: #fff;
  font-size: 1rem;
  cursor: pointer;
}
.teasers, .entries {
  position: fixed;
  overflow-y: auto;
  top: 4rem;
  height: calc(100vh - 4rem);//minus menu
  overflow: auto;
}
.teasers {
  background: #fff;
  height: calc(100vh - 10rem);
}
.teaser { cursor: pointer; }
@media(max-width: 768px) {
  .entries {
    height: calc(100vh - 4rem);
  }
}
@font-face {
  font-family: "Favorit";
  src: url("./assets/FavoritStd-Regular.woff2") format("woff2"),
       url("./assets/FavoritStd-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Favorit";
  src: url("./assets/FavoritStd-Bold.woff2") format("woff2"),
       url("./assets/FavoritStd-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Favorit";
  src: url("./assets/FavoritStd-Italic.woff2") format("woff2"),
       url("./assets/FavoritStd-Italic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Baskerville";
  src: url("./assets/LibreBaskerville-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Baskerville";
  src: url("./assets/LibreBaskerville-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Baskerville";
  src: url("./assets/LibreBaskerville-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
