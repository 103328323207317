<template>
  <div class="map" >
    <GmapMap
      :center="{lat:47.366807, lng:8.520332}"
      :zoom="15"
      :options="{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      }"
      map-type-id="roadmap"
      style="width: 100%; height: 300px"
      @click="handleClick"
      ref="gmap"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.googleLocation.geometry.location"
        :clickable="true"
        :draggable="true"
        @click="openWindow(m)"
      >
        <GmapInfoWindow :opened="m.infoWindowOpen">{{m.type}}: {{m.googleLocation.name}}</GmapInfoWindow>
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {
  name: 'Map',
  props: ['slide'],
  data() {
    return {
      markers: [],
      service: null,
    }
  },
  computed: {
    google: gmapApi
  },
  methods: {
    handleClick(place) {
      let placesToTest = ['primary_school', 'supermarket', 'park', 'cemetery', 'bus_station', 'church', 'atm']
      let that = this
      this.markers = []
      for (var i = 0; i < placesToTest.length; i++) {
        let request = {
          location: place.latLng,
          type: placesToTest[i],
          rankBy: this.google.maps.places.RankBy.DISTANCE,
        }
        let service = new this.google.maps.places.PlacesService(this.$refs.gmap.$mapObject);
        service.nearbySearch(request, function (results, status) {
          that.callback(results, status, request.type);
        });
      }
    },
    callback(results, status, type) {
      if (status == this.google.maps.places.PlacesServiceStatus.OK) {
        for (var i = 0; i < results.length; i++) {
          let markerName = results[i].icon
          if((markerName.includes(type)||markerName.includes('bank')||markerName.includes('school')||markerName.includes('train')||markerName.includes('bus')||markerName.includes('worship')||(markerName.includes('shopping') && type == 'supermarket')) && !markerName.includes('generic')/*&& results[i].types.length < 4*/){
            let myPlace = {}
            myPlace.googleLocation = results[i]
            myPlace.infoWindowOpen = false
            myPlace.type = type
            this.markers.push(myPlace)
            return
          }
        }
      } else
        console.log("Status not OK");
    },
    openWindow(marker) {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].infoWindowOpen = false
      }
      marker.infoWindowOpen = true
    },
  },
  mounted() {
  }
}
/*
//language: 'de',
//radius: 1000,
//keyword: 'bus stop',
//type: 'supermarket', //missing Barkat, one of the biggest supermarkets near here.
//type: 'school', //including "Din Skolo", paraworld.ch, Esalen Massage, Kiteshop Zürich, Schulsynode, Mathblitz – institutions that might teach something but that nobody would think is a school!
//type: 'transit_station', //including "Credinveste", Gemeindesekretariat (city hall), Vasco, Jüdisches Informationszentrum des Vereins Lema'an Achai, Goldblumen
*/
</script>

<style scoped lang="scss">
.map, .vue-map-container, .vue-map {
  width: 100%;
  min-height: calc(100vh - 15rem);
}
</style>
