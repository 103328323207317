<template>
  <div class="map" >
    <GmapMap
      :center="{lat:47.366807, lng:8.520332}"
      :zoom="15"
      :options="{
        mapTypeControl: true,
        streetViewControl: true,
        fullscreenControl: true,
      }"
      map-type-id="roadmap"
      style="width: 100%; height: 300px"
      @click="handleClick"
      ref="gmap"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.googleLocation"
        :clickable="true"
        :draggable="true"
        @click="openWindow(m)"
      >
        <GmapInfoWindow :opened="m.infoWindowOpen">{{m.type}}: {{m.name}}</GmapInfoWindow>
      </GmapMarker>
    </GmapMap>
    <div id="infos">
      <!--<div class="routetype">
        <input type="radio" id="openRoute" value="openRoute" v-model="service">
        <label for="openRoute">Openroute Service</label>
        <input type="radio" id="googleservice" value="googleservice" v-model="service">
        <label for="googleservice">Google</label>
        <br>
      </div>-->
      <span id="length">{{routeDuration ? 'Openroute: Dauer: ' : ''}}{{routeDuration}}</span> 
      <span id="distance">{{routeDistance ? ' Länge: ' : ''}}{{routeDistance}}{{routeDistance ? ' Km' : ''}}</span><br>
      <span id="length-google">{{routeDurationGoogle ? 'Google: Dauer: ' : ''}}{{routeDurationGoogle}}</span> 
      <span id="distance-google">{{routeDistanceGoogle ? ' Länge: ' : ''}}{{routeDistanceGoogle}}</span><br>
    </div>
  </div>
</template>

<script>
/*
I could: check in a small space, and if that doesn't get enough types, check in a larger radius.
*/

import {gmapApi} from 'vue2-google-maps'
var openrouteservice = require("openrouteservice-js");

export default {
  name: 'MapDirections',
  props: ['slide'],
  data() {
    return {
      markers: [],
      service: null,
      placesToTest: ['playground', 'bus', 'worship', 'cemetery', 'fountain', 'bench'],/*,'supermarket',  deleted sport and garden. 'bus', 'church', 'atm' 'cemetery', 'bus', 'church', 'atm', 'school', 'supermarket', */
      requests: {},
      currentPlace: null, //change when you click on the map, but stays stable for further loops.
      Directions: null, //openroute directions
      directionsService: null, //google directions
      directionsRenderer: null,
      route: null,
      coords: [],
      amDone: 0,
      routeDuration: null,
      routeDistance: null,
      routeDurationGoogle: null,
      routeDistanceGoogle: null,
      service: 'openRoute'
    }
  },
  computed: {
    google: gmapApi
  },
  methods: {
    handleClick(place) {
      /*        node(around:1000,47.36778617406171,8.519760521841757)["shop"="supermarket"];
              node(around:1000,47.36778617406171,8.519760521841757)["shop"="convenience"];*/
      this.currentPlace = place
      this.markers = []
      let radius = 100
      let simpleBounds = this.setBounds(radius)
      //starting and ending point?
      this.coords.push([this.currentPlace.latLng.toJSON().lng, this.currentPlace.latLng.toJSON().lat])
      for (var i = 0; i < this.placesToTest.length; i++) {
        this.getPois(this.placesToTest[i], simpleBounds, radius) //we just have to save radius in case we want to increase. maybe this should be saved by vue but it's in this loop.
      }
    },
    getPois(placeType, bounds, radius) {
        let that = this
        var request = `https://overpass.osm.ch/api/interpreter?data=
          [out:json][timeout:60][bbox:${bounds}];
          ${this.requests[placeType]}`
        fetch(request).then(function(response) {
          return response.json();
        }).then(function(data) {
          that.callback(data.elements, placeType, radius)
        }).catch(function(e) {
          console.log(e)
          console.log("Booo");
        });
    },
    setBounds(radius) {
      let mycenter = this.currentPlace.latLng;
      let circle = new google.maps.Circle({radius: radius, center: mycenter});
      let bounds = circle.getBounds();
      let aNorth  =   bounds.getNorthEast().lat();
      let aEast   =   bounds.getNorthEast().lng();
      let aSouth  =   bounds.getSouthWest().lat();
      let aWest   =   bounds.getSouthWest().lng();
      return `${aSouth},${aWest},${aNorth},${aEast}`
    },
    callback(results, type, radius) {
        if(results.length == 0 && radius < 5000 ) { //|| (type == 'cemetery' && radius < 5000)
          //if(radius == 4900) { console.log('last for type ' + type)}
          let newBounds = this.setBounds(radius + 100)
          this.getPois(type, newBounds, radius + 100)
        }
        //could just always take first place, it's close enough? or else calculate best and closest. 
        let i = 0
        if(results && results.length > 0) {
        //for (var i = 0; i < results.length; i++) {
          //if(!!results[i].tags.name || type == 'cemetery' || type == 'atm' || type == 'worship' || type == 'garden' || type == 'fountain' || type == 'playground' || type == 'sport' || type == 'recycling' || type == 'bench') {
            let myPlace = {}
            if(!!results[i].center) {
              myPlace.googleLocation = new google.maps.LatLng(parseFloat(results[i].center.lat), parseFloat(results[i].center.lon));
              this.coords.push([parseFloat(results[i].center.lon), parseFloat(results[i].center.lat)])
            } else {
              myPlace.googleLocation = new google.maps.LatLng(parseFloat(results[i].lat), parseFloat(results[i].lon));
              this.coords.push([parseFloat(results[i].lon), parseFloat(results[i].lat)])
            }
            myPlace.infoWindowOpen = false
            myPlace.name = results[i].tags.name
            myPlace.type = type
            this.markers.push(myPlace)
          }
        //}
        //once done getting places, get directions.
        if(results.length !== 0 || radius > 5000 ) {
          //runs after every type but i need to run after totally done.
          console.log(this.placesToTest.indexOf(type))
          this.amDone += 1
          console.log(this.amDone)
          if(this.amDone == this.placesToTest.length) {
            //if(this.service == 'openRoute') {
              this.getOrder(this.coords)
            //} else {
              this.getGoogleDirections(this.coords)
            //}
            this.amDone = 0
            this.coords = []
          }
        }
    },
    openWindow(marker) {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].infoWindowOpen = false
      }
      marker.infoWindowOpen = true
    },
    buildRequests() {
      this.requests['supermarket'] = `(
        node["shop"="supermarket"];
        node["shop"="convenience"];
        way["shop"="supermarket"];
      ); out center 30;`
      this.requests['school'] = `(
        way["amenity"="school"];
      ); out center 10;`
      this.requests['playground'] = `(
        node["leisure"="playground"];
        way["leisure"="playground"];
      ); out center 20;`
      /*node[leisure=playground]["name"~".+"];
        way[leisure=playground]["name"~".+"];
        rel[leisure=playground]["name"~".+"];
        node[leisure=playground];
        way[leisure=playground];
        rel[leisure=playground];
        node[leisure=recreation_ground];
        way[leisure=recreation_ground];
        rel[leisure=recreation_ground];
        node[landuse=recreation_ground];
        way[landuse=recreation_ground];
        rel[landuse=recreation_ground];
        node[landuse=greenfield];
        way[landuse=greenfield];
        rel[landuse=greenfield];
        node[landuse=meadow]["name"~".+"];
        way[landuse=meadow]["name"~".+"];
        rel[landuse=meadow]["name"~".+"];*/
      this.requests['cemetery'] = `(
        way["landuse"="cemetery"];
        relation["landuse"="cemetery"];
      ); out center 10;`
      this.requests['bus'] = `(
        node["highway"="bus_stop"];
      ); out center 10;`
      this.requests['worship'] = `(
        node["amenity"="place_of_worship"];
        way["amenity"="place_of_worship"];
        relation["amenity"="place_of_worship"];
      ); out center 10;`
      this.requests['atm'] = `(
        node["amenity"="atm"];
      ); out center 10;`
      this.requests['garden'] = `(
        way["landuse"="allotments"];
      ); out center 10;`
      /*        node["amenity"="drinking_water"];*/
      this.requests['fountain'] = `(
        node["amenity"="fountain"];
        way["amenity"="fountain"];
      ); out center 10;`
      this.requests['sport'] = `(
        node["leisure"="pitch"];
        way["leisure"="pitch"];
      ); out center 10;`
      this.requests['recycling'] = `(
        node["amenity"="recycling"];
        way["amenity"="recycling"];
      ); out center 10;`
      this.requests['bench'] = `(
        node["amenity"="bench"];
        way["amenity"="bench"];
      ); out center 10;`

    },
    getOrder(coords) {
      let that = this
      let request = new XMLHttpRequest();
      request.open('POST', "https://api.openrouteservice.org/optimization");
      request.setRequestHeader('Accept', 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8');
      request.setRequestHeader('Content-Type', 'application/json');
      request.setRequestHeader('Authorization', '5b3ce3597851110001cf62481cba955b8d6e4d17a3944c966f2d4241');
      request.onreadystatechange = function () {
        if (this.readyState === 4) {
          console.log(JSON.parse(this.responseText))
          let optimizedCoords = JSON.parse(this.responseText)
          let steps = optimizedCoords.routes[0].steps
          let newCoords = []
          for (let i = 0; i < steps.length; i++) {
            newCoords.push(steps[i].location)
          }
          console.log(newCoords)
          that.getDirections(newCoords)
        }
      };
      /*const body = '{"jobs":[{"id":1,"service":300,"amount":[1],"location":[1.98935,48.701],"skills":[1],"time_windows":[[32400,36000]]},{"id":2,"service":300,"amount":[1],"location":[2.03655,48.61128],"skills":[1]},{"id":3,"service":300,"amount":[1],"location":[2.39719,49.07611],"skills":[2]},{"id":4,"service":300,"amount":[1],"location":[2.41808,49.22619],"skills":[2]},{"id":5,"service":300,"amount":[1],"location":[2.28325,48.5958],"skills":[14]},{"id":6,"service":300,"amount":[1],"location":[2.89357,48.90736],"skills":[14]}],"vehicles":[{"id":1,"profile":"driving-car","start":[2.35044,48.71764],"end":[2.35044,48.71764],"capacity":[4],"skills":[1,14],"time_window":[28800,43200]},{"id":2,"profile":"driving-car","start":[2.35044,48.71764],"end":[2.35044,48.71764],"capacity":[4],"skills":[2,14],"time_window":[28800,43200]}]}';
      console.log(JSON.parse(body))*/
      let otimizeRequest = {
        jobs: [],
        vehicles: [{
          id: 1,
          profile: 'driving-car',
          capacity: [100],
          skills: [1],
          start: that.coords[0], //need to deal w this.
          end: that.coords[0], //need to deal w this.
          time_window: [0,86400], //24h?
        }],
      }
      for (let i = 0; i < coords.length; i++) {
        let newJob = {
          id: i,
          amount: [1],
          location: coords[i],
          service: 300,
          skills: [1],
          time_windows: [[0,86400]]
        }
        otimizeRequest.jobs.push(newJob)
      }
      let otimizeRequestString = JSON.stringify(otimizeRequest)
      request.send(otimizeRequestString);
    },
    getDirections(coords) {
      console.log(coords)
      let that = this
      that.$refs.gmap.$mapObject.data.forEach(function(feature) {
          that.$refs.gmap.$mapObject.data.remove(feature);
      });
      this.Directions.calculate({
        coordinates: coords,
        profile: "foot-hiking",
        format: "geojson",
      })
      .then(function(json) {
        console.log(json)
        let walkDuration = json.features[0].properties.summary.duration
        console.log(walkDuration)
        that.routeDuration = new Date(walkDuration * 1000).toISOString().substr(11, 8);
        that.routeDistance = Math.round(json.features[0].properties.summary.distance / 1000 * 100) / 100
        // Add your own result handling here
        that.$refs.gmap.$mapObject.data.addGeoJson(
            json
        )
      })
      .catch(function(err) {
        var str = "An error occurred: " + err;
        console.log(str);
      });
    },
    getGoogleDirections(coords){
      //google directions
      let that = this
      if(this.directionsService == null) {
        this.directionsService = new google.maps.DirectionsService();
      }
      if(this.directionsRenderer == null) {
        this.directionsRenderer = new google.maps.DirectionsRenderer({
          suppressMarkers: true
        });
      }
      this.directionsRenderer.setMap(this.$refs.gmap.$mapObject);
      let googleWayPoints = []
      for (let i = 1; i < coords.length; i++) {
        let newJob = {
          location: new google.maps.LatLng(parseFloat(coords[i][1]), parseFloat(coords[i][0])),
          stopover: true,
        }
        googleWayPoints.push(newJob)
      }
      this.directionsService
        .route({
          origin: new google.maps.LatLng(parseFloat(coords[0][1]), parseFloat(coords[0][0])),
          destination: new google.maps.LatLng(parseFloat(coords[0][1]), parseFloat(coords[0][0])),
          travelMode: google.maps.TravelMode.WALKING,
          waypoints: googleWayPoints,
          optimizeWaypoints: true,
        })
        .then((response) => {
          this.directionsRenderer.setDirections(response);
          console.log(response)
          let duration = 0
          let distance = 0
          for (let i = 0; i < response.routes[0].legs.length; i++) {
            duration += response.routes[0].legs[i].duration.value
            distance += response.routes[0].legs[i].distance.value
          }
          that.routeDurationGoogle = new Date(duration * 1000).toISOString().substr(11, 8);
          that.routeDistanceGoogle = Math.round(distance / 1000 * 100) / 100
          
        })
        .catch((e) =>console.log("Directions request failed due to " + status));
      
    }
  },
  mounted() {
    this.buildRequests()
    //openroute directions
    this.Directions = new openrouteservice.Directions({ api_key: "5b3ce3597851110001cf62481cba955b8d6e4d17a3944c966f2d4241"});
  }
}
/*
//language: 'de',
//radius: 1000,
//keyword: 'bus stop',
//type: 'supermarket', //missing Barkat, one of the biggest supermarkets near here.
//type: 'school', //including "Din Skolo", paraworld.ch, Esalen Massage, Kiteshop Zürich, Schulsynode, Mathblitz – institutions that might teach something but that nobody would think is a school!
//type: 'transit_station', //including "Credinveste", Gemeindesekretariat (city hall), Vasco, Jüdisches Informationszentrum des Vereins Lema'an Achai, Goldblumen
*/
</script>

<style scoped lang="scss">
.map, .vue-map-container, .vue-map {
  width: 100%;
  min-height: calc(100vh - 15rem);
}
::v-deep button { border-radius: 0; }
</style>
