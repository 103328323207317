<template>
  <div class="map" >
    <GmapMap
      :center="{lat:47.366807, lng:8.520332}"
      :zoom="15"
      :options="{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      }"
      map-type-id="roadmap"
      style="width: 100%; height: 300px"
      @click="handleClick"
      ref="gmap"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.googleLocation"
        :clickable="true"
        :draggable="true"
        @click="openWindow(m)"
      >
        <GmapInfoWindow :opened="m.infoWindowOpen">{{m.type}}: {{m.name}}</GmapInfoWindow>
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
/*
I could: check in a small space, and if that doesn't get enough types, check in a larger radius.
*/

import {gmapApi} from 'vue2-google-maps'

export default {
  name: 'Map',
  props: ['slide'],
  data() {
    return {
      markers: [],
      service: null,
      placesToTest: ['school', 'supermarket', 'playground', 'bus', 'worship', 'cemetery', 'garden', 'fountain', 'sport', 'recycling', 'bench'],/*, 'bus', 'church', 'atm' 'cemetery', 'bus', 'church', 'atm'*/
      requests: {},
      currentPlace: null, //change when you click on the map, but stays stable for further loops.
    }
  },
  computed: {
    google: gmapApi
  },
  methods: {
    handleClick(place) {
      /*        node(around:1000,47.36778617406171,8.519760521841757)["shop"="supermarket"];
              node(around:1000,47.36778617406171,8.519760521841757)["shop"="convenience"];*/
      this.currentPlace = place
      this.markers = []
      let radius = 100
      let simpleBounds = this.setBounds(radius)
      for (var i = 0; i < this.placesToTest.length; i++) {
        this.getPois(this.placesToTest[i], simpleBounds, radius) //we just have to save radius in case we want to increase. maybe this should be saved by vue but it's in this loop.
      }
    },
    getPois(placeType, bounds, radius) {
        let that = this
        var request = `https://overpass.osm.ch/api/interpreter?data=
          [out:json][timeout:60][bbox:${bounds}];
          ${this.requests[placeType]}`
        fetch(request).then(function(response) {
          return response.json();
        }).then(function(data) {
          that.callback(data.elements, placeType, radius)
        }).catch(function(e) {
          console.log(e)
          console.log("Booo");
        });
    },
    setBounds(radius) {
      let mycenter = this.currentPlace.latLng;
      let circle = new google.maps.Circle({radius: radius, center: mycenter});
      let bounds = circle.getBounds();
      let aNorth  =   bounds.getNorthEast().lat();
      let aEast   =   bounds.getNorthEast().lng();
      let aSouth  =   bounds.getSouthWest().lat();
      let aWest   =   bounds.getSouthWest().lng();
      return `${aSouth},${aWest},${aNorth},${aEast}`
    },
    callback(results, type, radius) {
        //console.log(results)
        //console.log(results.length)
        if(results.length == 0 && radius < 5000 ) { //|| (type == 'cemetery' && radius < 5000)
          //if(radius == 4900) { console.log('last for type ' + type)}
          let newBounds = this.setBounds(radius + 100)
          this.getPois(type, newBounds, radius + 100)
        }
        //could just always take first place, it's close enough? or else calculate best and closest. 
        for (var i = 0; i < results.length; i++) {
          //if(!!results[i].tags.name || type == 'cemetery' || type == 'atm' || type == 'worship' || type == 'garden' || type == 'fountain' || type == 'playground' || type == 'sport' || type == 'recycling' || type == 'bench') {
            let myPlace = {}
            if(!!results[i].center) {
              myPlace.googleLocation = new google.maps.LatLng(parseFloat(results[i].center.lat), parseFloat(results[i].center.lon));
            } else {
              myPlace.googleLocation = new google.maps.LatLng(parseFloat(results[i].lat), parseFloat(results[i].lon));
            }
            myPlace.infoWindowOpen = false
            myPlace.name = results[i].tags.name
            myPlace.type = type
            this.markers.push(myPlace)
          }
        //}
    },
    openWindow(marker) {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].infoWindowOpen = false
      }
      marker.infoWindowOpen = true
    },
    buildRequests() {
      this.requests['supermarket'] = `(
        node["shop"="supermarket"];
        node["shop"="convenience"];
        way["shop"="supermarket"];
      ); out center 30;`
      this.requests['school'] = `(
        way["amenity"="school"];
      ); out center 10;`
      this.requests['playground'] = `(
        node["leisure"="playground"];
        way["leisure"="playground"];
      ); out center 20;`
      /*node[leisure=playground]["name"~".+"];
        way[leisure=playground]["name"~".+"];
        rel[leisure=playground]["name"~".+"];
        node[leisure=playground];
        way[leisure=playground];
        rel[leisure=playground];
        node[leisure=recreation_ground];
        way[leisure=recreation_ground];
        rel[leisure=recreation_ground];
        node[landuse=recreation_ground];
        way[landuse=recreation_ground];
        rel[landuse=recreation_ground];
        node[landuse=greenfield];
        way[landuse=greenfield];
        rel[landuse=greenfield];
        node[landuse=meadow]["name"~".+"];
        way[landuse=meadow]["name"~".+"];
        rel[landuse=meadow]["name"~".+"];*/
      this.requests['cemetery'] = `(
        way["landuse"="cemetery"];
        relation["landuse"="cemetery"];
      ); out center 10;`
      this.requests['bus'] = `(
        node["highway"="bus_stop"];
      ); out center 10;`
      this.requests['worship'] = `(
        node["amenity"="place_of_worship"];
        way["amenity"="place_of_worship"];
        relation["amenity"="place_of_worship"];
      ); out center 10;`
      this.requests['atm'] = `(
        node["amenity"="atm"];
      ); out center 10;`
      this.requests['garden'] = `(
        way["landuse"="allotments"];
      ); out center 10;`
      this.requests['fountain'] = `(
        node["amenity"="drinking_water"];
        node["amenity"="fountain"];
        way["amenity"="fountain"];
      ); out center 10;`
      this.requests['sport'] = `(
        node["leisure"="pitch"];
        way["leisure"="pitch"];
      ); out center 10;`
      this.requests['recycling'] = `(
        node["amenity"="recycling"];
        way["amenity"="recycling"];
      ); out center 10;`
      this.requests['bench'] = `(
        node["amenity"="bench"];
        way["amenity"="bench"];
      ); out center 10;`

    },
  },
  mounted() {
    this.buildRequests()
  }
}
/*
//language: 'de',
//radius: 1000,
//keyword: 'bus stop',
//type: 'supermarket', //missing Barkat, one of the biggest supermarkets near here.
//type: 'school', //including "Din Skolo", paraworld.ch, Esalen Massage, Kiteshop Zürich, Schulsynode, Mathblitz – institutions that might teach something but that nobody would think is a school!
//type: 'transit_station', //including "Credinveste", Gemeindesekretariat (city hall), Vasco, Jüdisches Informationszentrum des Vereins Lema'an Achai, Goldblumen
*/
</script>

<style scoped lang="scss">
.map, .vue-map-container, .vue-map {
  width: 100%;
  min-height: calc(100vh - 15rem);
}
</style>
