<template>
  <div class="slide" :class="[{'background': (slide.background && slide.background !== '#fff') }, {'title-slide': slide.titleslide == true}, {'video-slide': slide.main[0].type == 'video'}]" :style="{background: slide.background}">
    <div class="title-link">
      <div class="title" v-html="slide.title"></div>
      <div class="smalltext" v-if="slide.underTitle" v-html="slide.underTitle"></div>
    </div>
    <div class="entry-content">
      <section v-for="(section, index) in slide.main" :key="index">
        <div class="big-text" v-if="section.type == 'bigText'" v-html="section.text" :class="{'smalltext': (slide.smalltext && slide.smalltext == true)}"></div>
        <mapTest v-if="section.type == 'mapTest'" :slide="slide" />
        <mapTestOsm v-if="section.type == 'mapTestOsm'" :slide="slide" />
        <mapTestOsmDirections v-if="section.type == 'mapTestOsmDirections'" :slide="slide" />
        <div class="image-container" v-if="section.type == 'image'">
          <img :src="require(`@/assets/img/${section.src}`)" />
        </div>
        <div class="video-container" v-if="section.type == 'video'">
          <iframe title="vimeo-player" :src="section.src" width="640" height="360" frameborder="0" allowfullscreen></iframe>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import mapTest from './mapTest.vue'
import mapTestOsm from './mapTestOsm.vue'
import mapTestOsmDirections from './mapTestOsmDirections.vue'

export default {
  name: 'Slide',
  props: ['slide'],
  components: {
    mapTest, mapTestOsm, mapTestOsmDirections,
  },
  mounted() {
    //console.log(this)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.slide {
  padding: 2rem 1rem 1rem 1rem;
  width: 100%;
  min-height: calc(100vh - 4rem);//minus menu
  box-sizing: border-box;
  &.background { color: #fff; }
  .title-link { padding-top: 2rem; }
}
.title-slide {
  background: #000;
  color: #fff;
  font-size: 3rem;
  line-height: 1;
  .title {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 2rem;
  }
  .entry-content {
    padding-top: 2rem;
    @media(min-width: 769px) {
      max-width: 86%;
    }
  }
  &.video-slide .entry-content { max-width: 100%; }
}
.smalltext {
  font-size: 1rem;
  font-family: 'Baskerville', 'Times', serif;
  line-height: 1.5;
  &.big-text {
    font-family: 'Favorit', sans-serif;
    font-size: 1.75rem;
    line-height: 1.2;
  }
}
@media(min-width: 769px) {
  .slide {
    display: grid;
    grid-template-columns: 2fr 5fr;
    grid-column-gap: 1rem;
    img {
      max-height: 80vh;
      display: block;
      margin: auto;
    }
  }
  .slide:not(.title-slide) .entry-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    section {
      max-width: 90%;
      width: 100%;
    }
  }
  .title-slide .title {
    max-width: 80%;
  }
}
@media(max-width: 600px) {
  .big-text {
    font-size: 2rem;
  }
  .slide {
    padding-bottom: 10rem;
  }
}
.video-container {
  display: flex;
  height: 56vw;
  @media(min-width: 600px) {
    height: 35vw;
    padding-left: 2rem;
  }
  align-content: flex-start;
}
iframe {
  width: 100%;
  height: auto;
}
</style>
