<template>
  <div class="teaser">
    <div class="title-link">
      <div class="title" v-html="title"></div>
      <button>Entdecken</button>
    </div>
    <div class="img">
      <img :src="require(`@/assets/img/${img}`)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Teaser',
  props: ['img', 'title'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.teaser {
  padding: 1rem;
}
.title {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 2rem;
}
.title-link {
  margin-bottom: 2rem;
}
@media(min-width: 769px) {
  .teaser {
    display: grid;
    grid-template-columns: 2fr 5fr;
    grid-column-gap: 1rem;
  }
  .title { max-width: 80%; }
  img { width: 100%; }
}
@media(max-width: 600px) {
  .title {
    /*font-size: 2rem;*/
  }
}
@media(min-width: 1200px) {
  img {
    max-width: 90%;
  }
}
</style>
