<template>
  <div class="entry">
    <Slide :slide="entry.slides[activeSlide]" />
    <div id="slidernav">
      <div class="arrows">
        <svg @click="goPrev()" xmlns="http://www.w3.org/2000/svg" width="38.917" height="33.922" viewBox="0 0 38.917 33.922">
          <g id="Group_1634" data-name="Group 1634" transform="translate(-30.086 -545.793)">
            <line id="Line_15" data-name="Line 15" x2="36.975" transform="translate(32.028 562.875)" fill="none" :stroke="navColor" stroke-width="2"/>
            <path id="Path_2823" data-name="Path 2823" d="M-1246.892,2536.44l-16.254,16.254,16.254,16.254" transform="translate(1294.646 -1989.94)" fill="none" :stroke="navColor" stroke-width="2"/>
          </g>
        </svg>
        <svg @click="goNext()" xmlns="http://www.w3.org/2000/svg" width="38.917" height="33.922" viewBox="0 0 38.917 33.922">
          <g id="Group_1635" data-name="Group 1635" transform="translate(69.003 579.715) rotate(180)">
            <line id="Line_15" data-name="Line 15" x2="36.975" transform="translate(32.028 562.875)" fill="none" :stroke="navColor" stroke-width="2"/>
            <path id="Path_2823" data-name="Path 2823" d="M-1246.892,2536.44l-16.254,16.254,16.254,16.254" transform="translate(1294.646 -1989.94)" fill="none" :stroke="navColor" stroke-width="2"/>
          </g>
        </svg>
      </div>
      <div class="counter" :style="{color: navColor}">
        {{activeSlide + 1}}/{{entry.slides.length}}
      </div>
    </div>
  </div>
</template>

<script>
import Slide from './Slide.vue'

export default {
  name: 'Entry',
  components: {
    Slide
  },
  props: ['entry'],
  data() {
    return {
      activeSlide: 0,
    }
  },
  computed: {
    navColor() {
      return this.activeSlide == 0 || ('background' in this.entry.slides[this.activeSlide] && this.entry.slides[this.activeSlide].background !== '#fff') ? '#fff' : '#000'
    }
  },
  methods: {
    goNext() {
      if(this.activeSlide < this.entry.slides.length - 1) { this.activeSlide = this.activeSlide + 1 } else {
        this.activeSlide = 0
        this.$parent.entryMode = false
      }
    },
    goPrev() {
      if(this.activeSlide > 0) {
        this.activeSlide = this.activeSlide - 1
      } else {
        this.activeSlide = 0
        this.$parent.entryMode = false
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 2rem;
}
#slidernav {
  display: grid;
  grid-template-columns: 2fr 5fr;
  width: calc(100vw - 2rem);
  grid-column-gap: 1rem;
}
#slidernav {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  svg {
    padding-right: 1rem;
    cursor: pointer;
  }
}
@media(min-width: 769px) {
  .title {
    max-width: 80%;
  }
}
@media(max-width: 768px) {
  #slidernav {
    position: relative;
    bottom: 4rem;
    height: 0;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
