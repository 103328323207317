<template>
  <div class="nav">
    <div class="name" @click="goHome()">Fremd Zuhause</div>
    <div class="subtitle">Blog</div>
    <div class="menu-icon">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  methods: {
    goHome() {
      this.$parent.entryMode = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #000;
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 500;
  font-size: 1.1rem;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  padding: 1rem;
  box-sizing: border-box;
}
.name { cursor: pointer; }
@media(min-width: 600px) {
  .nav {
    display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    align-items: center;
    padding: 1rem;
  }
}
.menu-icon {
  width: 52px;
  height: 2rem;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-self: right;
}

.menu-icon span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}
.menu-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.menu-icon span:nth-child(2) {
  top: 16px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.menu-icon span:nth-child(3) {
  top: 32px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.menu-icon.close span:nth-child(1) {
  transform: rotate(25deg);
  top: 2px;
  left: 5px;
}

.menu-icon.close span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.menu-icon.close span:nth-child(3) {
  transform: rotate(-25deg);
  top: 25px;
  left: 5px;
}

</style>
